import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import {InputWithButton} from '../shared/input-with-button';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';
import {ArticleCard} from './article-card';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 204px;

  @media screen and ${device.tablet} {
    padding-bottom: 76px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 550px;
  grid-template-rows: auto 1fr;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 169px;
  grid-row-gap: 24px;
  grid-template-areas:
    'header article'
    'text article';

  @media screen and ${device.tablet} {
    padding: 115px 24px 0;
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'text' 'article';
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 0;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #d6e7f8;
  letter-spacing: -0.1px;
  margin-bottom: 12px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
  }
`;

const Social = styled.div`
  margin-top: 26px;
  color: #d6e7f8;
  display: flex;
  align-items: center;
  
  a {
    color: #7484a9;
    text-decoration: none;
    margin-left: 20px;
    height: 20px;
    
    img {
      height: 20px;
      filter: brightness(1.2);
    }

    &:hover {
      color: #0a0a0a;

      img {
        filter: brightness(2);
      }
    }
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  inputPlaceholder: string;
  buttonText: string;
  articleImg: string;
  articleCategoryName: string;
  articleCategoryUrl: string;
  articleTitle: string;
  articleUrlFriendlyName: string;
  articleAuthorAvatar: string;
  articleAuthorFirstName: string;
  articleAuthorLastName: string;
  articleReadingTime: string;
  socialMediaLabel: string;
  socialLinks: Array<{
    logo: string;
    url: string;
    alt: string;
  }>;
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <div style={{gridArea: 'text'}}>
          <Text>
            <ReactMarkdown>{props.text}</ReactMarkdown>
          </Text>
          <InputWithButton
            inputPlaceholder={props.inputPlaceholder}
            buttonText={props.buttonText}
            buttonColor={'green'}
            inputWidth={339}
          />
          <Social>
            <span>{props.socialMediaLabel}</span>
            {
              props.socialLinks.map((item, index) => (
                <a href={item.url} key={index}>
                  <img src={item.logo} alt={item.alt}/>
                </a>
              ))
            }
          </Social>
        </div>
        <div style={{gridArea: 'article'}}>
          <ArticleCard
            img={props.articleImg}
            categoryName={props.articleCategoryName}
            categoryUrl={props.articleCategoryUrl}
            title={props.articleTitle}
            urlFriendlyName={props.articleUrlFriendlyName}
            authorAvatar={props.articleAuthorAvatar}
            authorFirstName={props.articleAuthorFirstName}
            authorLastName={props.articleAuthorLastName}
            readingTime={props.articleReadingTime}
          />
        </div>
      </Wrapper>
    </Container>
  );
};
