import React from 'react';
import styled from 'styled-components';
import AvatarPlaceholder from '../../images/man.svg';

const Container = styled.div<{ width?: string }>`
  background: white;
  border-radius: 9px;
  box-shadow: rgba(41, 45, 52, 0.3) 0 10px 25px 0;
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
`;

const Avatar = styled.img`
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-right: 10px;
`;

const Author = styled.a`
  font-size: 12px;
  color: #292d34;
  font-weight: 600;
  text-decoration: none;
`;

const Time = styled.span`
  color: #7c828d;
  font-size: 12px;

  i {
    font-size: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }
`;

const Category = styled.a`
  display: inline-block;
  text-transform: uppercase;
  color: #292d34;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 6px;
  text-decoration: none;
`;

const Title = styled.a`
  display: block;
  color: #292d34;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  text-decoration: none;
`;

export const ArticleCard = (props: {
  width?: string;
  img?: string;
  alt?: string;
  categoryName: string;
  categoryUrl: string;
  title: string;
  urlFriendlyName: string;
  authorAvatar: string | undefined;
  authorFirstName: string;
  authorLastName: string;
  readingTime: string;
}): JSX.Element => {

  return (
    <Container width={props.width}>
      <img width={'100%'} height={'auto'} src={props.img} alt={props.alt}/>
      <div style={{padding: 30}}>
        <Category href={`/blog/${props.categoryUrl}`}>{props.categoryName}</Category>
        <Title href={`/blog/${props.categoryUrl}/${props.urlFriendlyName}`}>{props.title}</Title>
        <div style={{marginTop: 'auto', display: 'flex', alignItems: 'center'}}>
          <Avatar src={props.authorAvatar?.length as number > 0 ? props.authorAvatar : AvatarPlaceholder}/>
          <Author
            href={`/blog/authors/${props.authorFirstName.toLowerCase()}-${props.authorLastName.toLowerCase()}`}
          >
            {props.authorFirstName} {props.authorLastName}
          </Author>
          <Time style={{marginLeft: 'auto'}}>
            <i className={'icon-time'}/>
            {props.readingTime}
          </Time>
        </div>
      </div>
    </Container>
  );
};
