import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {MarkdownRemark} from '../models/markdown-remark';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import readingTime from 'reading-time';
import {ArticleCard} from '../components/blog/article-card';
import AvatarPlaceholder from '../images/man.svg';
import {TitleSection} from '../components/blog/title-section';
import styled from 'styled-components';
import {device} from '../models/devices';

interface BlogPageData extends BasePageData {
  categories: MarkdownRemark<{
    name: string;
    urlFriendlyName: string;
  }>;
  articles: MarkdownRemark<{
    title: string;
    urlFriendlyName: string;
    subTitle: string;
    category: string;
    author: string;
    image: string;
    Body: string;
  }>;
  authors: MarkdownRemark<{
    firstName: string;
    lastName: string;
    image: string;
  }>;
  pageData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    subTitle: string;
    inputPlaceholder: string;
    buttonText: string;
    socialMediaLabel: string;
  }>;
}

const ArticlesList = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 39px;
  column-gap: 31px;
  max-width: 1170px;
  margin: -80px auto 50px;

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    margin: -40px 24px 40px;
  }
`;

const BlogPage = ({data}: {data: BlogPageData}): JSX.Element => {
  const articles = data.articles.edges
    .map(({node}) => node.frontmatter)
    .map(article => ({
      ...article,
      author: data.authors.edges
        .map(i => i.node.frontmatter)
        .find(author => article.author === `${author.firstName}-${author.lastName}`),
      readingTime: readingTime(article.Body).text,
      category: data.categories.edges
        .map(({node}) => node.frontmatter)
        .find(category => article.category === category.name)
    }));

  const socialLinks = data.navigation.edges[0].node.frontmatter.socialLinks
    .map(item => ({
      logo: item.navItemName,
      url: item.url,
      alt: item.altText
    }));

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.pageData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        contentType={'blog-post'}
      />
      <TitleSection
        title={data.pageData.edges[0].node.frontmatter.title}
        text={data.pageData.edges[0].node.frontmatter.subTitle}
        inputPlaceholder={data.pageData.edges[0].node.frontmatter.inputPlaceholder}
        buttonText={data.pageData.edges[0].node.frontmatter.buttonText}
        articleImg={articles[0].image}
        articleCategoryName={articles[0].category?.name ?? ''}
        articleCategoryUrl={articles[0].category?.urlFriendlyName ?? ''}
        articleTitle={articles[0].title}
        articleUrlFriendlyName={articles[0].urlFriendlyName}
        articleAuthorAvatar={articles[0].author?.image ?? AvatarPlaceholder}
        articleAuthorFirstName={articles[0].author?.firstName ?? ''}
        articleAuthorLastName={articles[0].author?.lastName ?? ''}
        articleReadingTime={articles[0].readingTime}
        socialLinks={socialLinks}
        socialMediaLabel={data.pageData.edges[0].node.frontmatter.socialMediaLabel}
      />
      <ArticlesList>
        {articles.slice(1).map((article, index) => (
          <ArticleCard
            key={index}
            img={article.image}
            categoryName={article.category?.name ?? ''}
            categoryUrl={article.category?.urlFriendlyName ?? ''}
            urlFriendlyName={article.urlFriendlyName}
            title={article.title}
            authorAvatar={article.author?.image ?? AvatarPlaceholder}
            authorFirstName={article.author?.firstName ?? ''}
            authorLastName={article.author?.lastName ?? ''}
            readingTime={article.readingTime}
          />
        ))}
      </ArticlesList>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  {
    ...BaseData
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*categories//"}
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            urlFriendlyName
          }
        }
      }
    }
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*blogs//"},
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            urlFriendlyName
            category
            author
            image
            Body
          }
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/^(?!.*/site/).*authors//"},
      }
    ) {
      edges {
        node {
          frontmatter {
            firstName
            lastName
            image
          }
        }
      }
    }
    pageData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/blog.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            subTitle
            inputPlaceholder
            buttonText
            socialMediaLabel
          }
        }
      }
    }
  }
`;
